const ChangeDateformat = (date, type="date") => {

  /// type should be "date" or "datetime";

  if((Date.parse(date) !== NaN && Date.parse(date) > 0)){

    const gettedDate = new Date(date); 
    let convertedDate
    if(type=="date"){ 
      convertedDate = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",  
      }).format(gettedDate);  
    }else {
      convertedDate = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit", 
        hour:'2-digit',
        minute:'2-digit',
        second:'2-digit',
        hour12: false
      }).format(gettedDate);  
    }
    console.log("gettedDate", gettedDate, convertedDate);
    return convertedDate;
    
  }else {
    return '';
  } 
    
};

export default ChangeDateformat;