import React, { useState } from "react";
import UseCookiesValue from "./useCookiesValue";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "src/store/features/userdataSlice";
import { updatedivisionstore } from "src/store/features/divisionSlice";
import { loaderDisable, loaderEnable } from "src/store/features/loaderSlice";
import Roles from "src/auth/roles";
 
const CryptoJS = require("crypto-js");
const secure_salt_key = process.env.REACT_APP_SECURE_SALT_KEY
const SESSION_COOKIES_NAME = process.env.REACT_APP_SESSION_COOKIES_NAME;
const BACKEND_BASE_URL = process.env.REACT_APP_FLEET_BACKEND_BASE_URL;

const useAuth = (initialValue = false) => {
  const [isAuth, setIsAuth] = useState(initialValue);
  const dispatch = useDispatch();
  const userdata = useSelector(state=>state.userdata)[0];

  console.log("userdata useAuth", userdata);

  const { deleteCookiesData } = UseCookiesValue();

  const login = () => {
    setTimeout(() => {
      setIsAuth(true);
    }, 2000);
 
  };

  const resetFun = () => {
    let divisionPayload = {
        division_id: null,
        division_name: null,
        company_id: null,
        company_name: null,
        dotco_id: null,
        dotco_name: null,
      };
  
      dispatch(updatedivisionstore(divisionPayload)); 
      dispatch(setUserData({}));
 
      deleteCookiesData('isSessionTimedOut'); 
      deleteCookiesData(SESSION_COOKIES_NAME); 
  }

  const resetAuth = () => { 
    dispatch(loaderEnable())   
    resetFun()
    
    setTimeout(() => {
        dispatch(loaderDisable())
        window.location.href = `${BACKEND_BASE_URL}sso`
    }, 1000);

  }; 

  const logout = () => {
    dispatch(loaderEnable())   
    resetFun()
    
    setTimeout(() => {
        dispatch(loaderDisable())
        window.location.href = userdata.logout_url
    }, 1000);
  };

  // const bytes = CryptoJS.AES.decrypt(userdata['access_level'], secure_salt_key);
  // const decryptAccessValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // const roles = decryptAccessValue == 1 ? 'ADMIN' : 'USER';
  // console.log('--- secure_salt_key ---', secure_salt_key, roles);
  const access = (type='') => { 
    
    const bytes = Object.keys(userdata).length > 0 ? CryptoJS.AES.decrypt(userdata['access_level'], secure_salt_key) : '';
    const decryptAccessValue =  Object.keys(userdata).length > 0 ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : '';
    const roles = decryptAccessValue == 1 ? 'ADMIN' : 'USER';
    
    if(type !== ''){ 
      return roles == type.toUpperCase();
    }else { 
      return roles;
    }
     
  };

  const isCurrentUserHasPermissionToPermitAccess = ()=>{
    let permitAuthorisedUser = [
      "ddeveloper", "curlyjoe", "spinqa", "pratik", "nanette", "testuser5", "testcredlevel2", "tiffanyj", "nanette", "prtest99", "prtest2",
      "d3testuser2","d3testuser3","d3testuser4","d3testuser5","d3testuser6"

    ]
    let loggedInUserName = userdata.username.toLowerCase();
    console.log("loggedInUserName", loggedInUserName);
    
    return permitAuthorisedUser.includes(loggedInUserName) ? [ Roles.ADMIN, Roles.USER ] : [];
   
  }


  return { isAuth, login, logout, resetAuth, access, isCurrentUserHasPermissionToPermitAccess };
};

export { useAuth };
