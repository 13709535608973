import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CLink,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import PixelTracking from "../shared/components/PixelTracking";
import { Link } from "react-router-dom";
import styles from "../shared/association_company_division.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { FormFieldError } from "src/helpers";
import Multiselect from "multiselect-react-dropdown";
import ApiDropdowns from "src/apis/dropdowns";
import useApiMessageHandler from "src/helpers/useApiMessageHandler";
import ApiAutoSuggestions from "src/apis/auto_suggestions";
import ApiExtraApi from "src/apis/ext_api";
import ApiPermitOrders from "src/apis/permit_orders";
import ApiCompanies from "src/apis/companies";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import ApiVehicles from "src/apis/vehicles";
import ApiDriver from "src/apis/Driver"; 
import ChangeDateformat from "src/helpers/changeDateformat";
import { DatePicker, Space } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ApiFleetStatus from "src/apis/fleet_status";
import dayjs from "dayjs";
import Autosuggest from "react-autosuggest/dist/Autosuggest"; 
import { TagsInput } from "react-tag-input-component-2";
import { useDocumentTitle } from "src/helpers/useDocumentTitle";

const formValidationSchema = yup.object().shape({
  legal_name: yup
    .string("Legal Name should be string.")
    .required("Legal Name is required."),
  address: yup
    .string("Address should be string.")
    .required("Address is required."),
//   city: yup.string("City should be string.").required("City is required."),
//   state: yup.string("State should be string.").required("State is required."), 
  zip: yup.string()
  .nullable()
  .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits"), 
  use_for_mailing: yup
    .string()
    .required("Use for mailing address is required."),
  mail_address: yup
    .string("Mailing address should be string.")
    .when("use_for_mailing", {
      is: (ref) => ref == "no",
      then: yup.string().required("Mailing address is required."),
    }),
//   mail_state: yup
//     .string("Mailing state should be string.")
//     .when("use_for_mailing", {
//       is: (ref) => ref == "no",
//       then: yup.string().required("Mailing state is required."),
//     }),
//   mail_city: yup
//     .string("Mailing city should be string.")
//     .when("use_for_mailing", {
//       is: (ref) => ref == "no",
//       then: yup.string().required("Mailing city is required."),
//     }), 
  mail_zip:  yup.string()
  .nullable()
  .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits"), 
  fid: yup.string().required("FID is required."),
//   ssn: yup.string().required("SSN is required."),
  dot_number: yup.string().required("DOT is required."),
  ca_id: yup.string("CA should be string.").when("dot_number", {
    is: (ref) => ref == "",
    then: yup.string().when("state", {
        is: (ref) => ref == "CA",
        then: yup.string().required("CA is required."),
      }),
  }),
  kyu_id: yup.string("KYU should be string.").when("state", {
    is: (ref) => ref == "KY",
    then: yup.string().required("KYU is required."),
  }),
  
  // business_type : yup.object({checked: yup.array().min(1, 'Select atleast one option of your interest') }),
  start_address: yup.string().required("Address is required."),
  start_city: yup.string().required("City is required."),
  start_state: yup.string().required("State is required."), 
  start_zip:  yup.string().required("Zip is required.").matches(/^[0-9]+$/, "Must be only digits"),
  end_address: yup.string().required("Address is required."),
  end_city: yup.string().required("City is required."),
  end_state: yup.string().required("State Year is required."), 
  end_zip: yup.string().required("Zip is required.").matches(/^[0-9]+$/, "Must be only digits"),
  route_states : yup.array().min(1, "Route States are required").required("Route States are required"),
  route_cities:  yup.array().min(1, "Route Cities is required").required("Route Cities are required"),
  
  route_freeways: yup.array().min(1, "Route Freeways are required").required("Route Freeways are required"),
  trip_type: yup.string().required("Initial Trip is required."),
  approx_date_time: yup.string().when("trip_type", {
    is: (ref) => ref != "return_trip",
    then: yup.string().required("Approx Date and Time are required."),
  }),
  approx_miles: yup.string().when("trip_type", {
    is: (ref) => ref != "return_trip",
    then: yup.string().required("Approx miles are required."),
  }),
  approx_return_date_time: yup
    .string("Date of return Date and Time are required.")
    .when("trip_type", {
      is: (ref) => ref == "return_trip" || ref == "both",
      then: yup.string().required("Date of return Date and Time are required."),
  }),
  approx_miles_return: yup
    .string()
    .when("trip_type", {
      is: (ref) => ref == "return_trip" || ref == "both",
      then: yup.string().required("Approx miles are required"),
  }),
  driver_name: yup.string().required("Driver Name is required."),
  license_number: yup.string().required("License Number is required."),
  license_class: yup.string().required("License Class is required."),
  carrier: yup.string().required("Carrier are required."),
  policy_number: yup.string().required("Policy Number is required."),
  expiration_date: yup.string().required("Expiration Date is required."),

  registered_owner: yup
    .string("Registerd Owner is required.")
    .when("is_vehicle_leased", {
      is: (ref) => ref == "yes",
      then: yup.string().required("Registerd Owner is required."),
    }),
  registered_owner_address: yup
    .string("CA should be string.")
    .when("is_vehicle_leased", {
      is: (ref) => ref == "yes",
      then: yup.string().required("Registerd Owner Address is required."),
    }),
    registered_owner_zip: yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits"),
  vehicle_year: yup.string().required("Vehicle Year is required.")
  .nullable()
  .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits").min(4, "Please enter valid format (XXXX) ").max(4, "Please enter valid format (XXXX)"),
  vehicle_vin: yup.string().required("Vehicle VIN is required.").test("len", "VIN length needs to be 6 or more.", (val) => val != undefined &&  val.length > 5),
  vehicle_type: yup.string().required("Vehicle Type is required."),
  vehicle_unit_number: yup
    .string()
    .required("Vehicle Unit Number is required."),
  vehicle_license_number: yup.string().required("License Number is required."),
  vehicle_license_state: yup
    .string()
    .required("License State Number is required."),
  // vehicle_license_plate_expriration : yup.string().required("License State Number is required."),
  // vehicle_fuel_type: yup.string().required("Vehicle Fuel Type is required."),
  vehicle_lic_exp_date: yup
    .string()
    .required("License Plate Expiration is required."),
  vehicle_registered_weight_rating: yup
    .string()
    .required("Weight rating is required."),
vehicle_color: yup.array().when("vehicle_license_state", {
        is: (ref) => ref == "TX",
        then: yup.array().min(1, "Vehicle color is required for Texas").required("Vehicle color is required for Texas"),
    }),
  bill_of_lading: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Bill of lading is required."),
  }),
  bill_description: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Bill description is required."),
  }),
  load_weight: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Load weight is required."),
  }),
  overall_gvw: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Overall GVW is required."),
  }),
  load_dimen_length_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_length_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Length is required."),
    }),
  }),
  load_dimen_length_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_length_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Length is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  load_dimen_width_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_width_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required."),
    }),
  }),
  load_dimen_width_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_width_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  load_dimen_height_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_height_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required."),
    }),
  }),
  load_dimen_height_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("load_dimen_height_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }), 
  overall_dimen_length_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_length_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Length is required."),
    }),
  }),
  overall_dimen_length_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_length_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Length is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  overall_dimen_width_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_width_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required."),
    }),
  }),
  overall_dimen_width_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_width_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Width is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  overall_dimen_height_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_height_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Height is required."),
    }),
  }),
  overall_dimen_height_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overall_dimen_height_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Heigh is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  overhang_front_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overhang_front_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Front is required."),
    }),
  }),
  overhang_front_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overhang_front_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Front is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  overhang_rear_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overhang_rear_inch", {
      is: (ref) => ref == "",
      then: yup.string().required("Rear is required."),
    }),
  }),
  overhang_rear_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("overhang_rear_feet", {
      is: (ref) => ref == "",
      then: yup.string().required("Rear is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
    }),
  }),
  axle_count: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup
      .string()
      .required("Axle-Count is required."),
  }),
  axle_spacing_1_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_1_inch", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 1 || ref > 1,
        then: yup.string().required("Axle Spacing is required.")
      }),
    }),
  }),
  axle_spacing_1_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_1_feet", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 1 || ref > 1,
        then: yup.string().required("Axle Spacing is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    }),
  }),
  axle_spacing_2_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_2_inch", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 2 || ref > 2,
        then: yup.string().required("Axle Spacing is required.")
      }),
    }),
  }),
  axle_spacing_2_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_2_feet", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 2 || ref > 2,
        then: yup.string().required("Axle Spacing is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    }),
  }),
  axle_spacing_3_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_3_inch", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 3 || ref > 3,
        then: yup.string().required("Axle Spacing is required.")
      }),
    }),
  }),
  axle_spacing_3_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_3_feet", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 3 || ref > 3,
        then: yup.string().required("Axle Spacing is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    }),
  }),
  axle_spacing_4_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_4_inch", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 4,
        then: yup.string().required("Axle Spacing is required.")
      }),
    }),
  }),
  axle_spacing_4_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_spacing_4_feet", {
      is: (ref) => ref == "",
      then: yup.string().when("axle_count", {
        is: (ref) => ref == 4,
        then: yup.string().required("Axle Spacing is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    }),
  }),
  axle_weight_1: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 1 || ref > 1,
      then: yup.string().required("Axle Weight is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_weight_2: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 2 || ref > 2,
      then: yup.string().required("Axle Weight is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_weight_3: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 3 || ref > 3,
      then: yup.string().required("Axle Weight is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_weight_4: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 4,
      then: yup.string().required("Axle Weight is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_width_1: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 1 || ref > 1,
      then: yup.string().required("Axle Tire is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_width_2: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 2 || ref > 2,
      then: yup.string().required("Axle Tire is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_width_3: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 3 || ref > 3,
      then: yup.string().required("Axle Tire is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_width_4: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 4,
      then: yup.string().required("Axle Tire is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_count_1: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 1 || ref > 1,
      then: yup.string().required("Axle tire count is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_count_2: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 2 || ref > 2,
      then: yup.string().required("Axle tire count is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_count_3: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 3 || ref > 3,
      then: yup.string().required("Axle tire count is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  axle_tire_count_4: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("axle_count", {
      is: (ref) => ref == 4,
      then: yup.string().required("Axle tire count is required.")
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits")
    })
  }),
  trailer_dimen_length_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_length_inch", {
        is: (ref) => ref == "",
        then: yup.string().required("Length is required."),
      }),
    })
  }),
  trailer_dimen_length_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_length_feet", {
        is: (ref) => ref == "",
        then: yup.string().required("Length is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    })
  }),
  trailer_dimen_width_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_width_inch", {
        is: (ref) => ref == "",
        then: yup.string().required("Width is required."),
      }),
    })
  }),
  trailer_dimen_width_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_width_feet", {
        is: (ref) => ref == "",
        then: yup.string().required("Width is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    })
  }),
  trailer_dimen_height_feet: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_height_inch", {
        is: (ref) => ref == "",
        then: yup.string().required("Height is required."),
      }),
    })
  }),
  trailer_dimen_height_inch: yup.string().when("is_vehicle_overweight", {
    is: (ref) => ref == "yes",
    then: yup.string().when("towing_trailer", {
      is: (ref) => ref == "yes",
      then: yup.string().when("trailer_dimen_height_feet", {
        is: (ref) => ref == "",
        then: yup.string().required("Height is required.").test("max", "The inch value needs to be 11 or less.", (val) => Number(val) < 12),
      }),
    }) 
  }), 
  //   trailer_make: yup.string().when("towing_trailer", {
  //     is: (ref) => ref == "yes",
  //     then: yup.string().required("Make is required."),
  //   }),
  trailer_year: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Trailer Year is required.")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)).matches(/^[0-9]+$/, "Must be only digits").min(4, "Please enter valid format (XXXX) ").max(4, "Please enter valid format (XXXX)"),
  }),
  trailer_vin: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("VIN is required.").test("len", "VIN length needs to be 6 or more.", (val) => val != undefined && val.length > 5),
  }),
  trailer_unit_number: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Unit Number is required."),
  }),
  trailer_license_number: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("License Number is required."),
  }),
  trailer_license_state: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("License State is required."),
  }),
  // trailer_registered_gross_weight_rating: yup.string().when("towing_trailer", {
  //   is: (ref) => ref == "yes",
  //   then: yup.string().required("Gross Weight Rating is required."),
  // }),
  trailer_axle_number: yup.string().when("towing_trailer", {
    is: (ref) => ref == "yes",
    then: yup.string().required("Axle Number is required."),
  }),
//   trailer_empty_weight: yup.string().when("towing_trailer", {
//     is: (ref) => ref == "yes",
//     then: yup.string().required("Empty Weight is required."),
//   }),
  trailer_color: yup.array().when("trailer_license_state", {
    is: (ref) => ref == "TX",
    then: yup.array().min(1, "Trailer color is required for Texas").required("Trailer color is required for Texas"),
}),
});

const PermitOrderForm = () => {
  useDocumentTitle("Permit Order | Fleet");
  const Api_Dropdowns = ApiDropdowns();
  const Api_AutoSuggestions = ApiAutoSuggestions();
  const Api_Companies = ApiCompanies();
  const Api_Driver = ApiDriver();
  const Api_PermitOrders = ApiPermitOrders();
  const Api_Vehicles = ApiVehicles();
  const Api_FleetStatus = ApiFleetStatus();
  
  const { catchErrorsController, backendMessagesController } =
    useApiMessageHandler();
  const {
    register,
    getFieldState,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValue: {},
    resolver: yupResolver(formValidationSchema),
  });

  const backendFieldSpecificErrorHandler = (errorsData) => {
    for (let error in errorsData) {
      setError(error, errorsData[error]);
    }
  };

  const itemsRefs = useRef([]); 
  const [isTripDetails, setIsTripDetails] = useState(false);
  const [useForMailing, setUseForMailing] = useState(false);
  const [isVehicleLeased, setIsVehicleLeased] = useState(false);
  const [businessTypeRadio, setBusinessTypeRadio] = useState("llc_llp");
  const [tripTypeRadio, setTripTypeRadio] = useState("initial_trip");
  const [isOverweightOrDimensional, setIsOverweightOrDimensional] =
    useState(false);
  const [isTowingTrailer, setIsTowingTrailer] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  
  const [activeSuggestionTypeName, setActiveSuggestionTypeName] = useState('');
  const [suggestionLegalValue, setSuggestionLegalValue] = useState('');
  const [suggestionDriverValue, setSuggestionDriverValue] = useState('');
  const [cityDotcoValue, setCityDotcoValue] = useState('');
  const [cityMailingValue, setCityMailingValue] = useState('');
  const [cityStartValue, setCityStartValue] = useState('');
  const [cityEndValue, setCityEndValue] = useState('');
  const [cityRegisteredOwnerValue, setCityRegisteredOwnerValue] = useState('');
  const [vehicleVinValue, setVehicleVinValue] = useState('');
  const [trailerVinValue, setTrailerVinValue] = useState('');
  const [freeWaysTags, setFreeWaysTags] = useState();
  
  const [selectedVehicleColor, setSelectedVehicleColor] = useState([]);
  const [selectedTrailerColor, setSelectedTrailerColor] = useState([]);
  const [selectedRouteStates, setSelectedRouteStates] = useState([]);
  const [selectedRouteCities, setSelectedRouteCities] = useState([]);
  const [searchedLegalData, setSearchedLegalData] = useState([]);
  const [searchLegalBoxText, setSearchLegalBoxText] = useState([]);
  const [searchedDriverData, setSearchedDriverData] = useState([]);
  const [searchDriverBoxText, setSearchDriverBoxText] = useState([]);
  const [searchedCityData, setSearchedCityData] = useState([]);
  const [searchedCityFieldName, setSearchedCityFieldName] = useState();
  const [searchedVinData, setSearchedVinData] = useState([]);
  const [searchedVinFieldName, setSearchedVinFieldName] = useState();
  const [vinNotFoundError, setVinNotFoundError] = useState(false);
  const [routeCityOptionList, setRouteCityOptionList] = useState();

  useEffect(() => {
    let dropdownParam = [
      "states", 
      "permit_form_vehicle_types",
      "vehicle_colors",
      "vehicle_fuel_types",
    ];
    Api_Dropdowns.getAll(dropdownParam)
      .then((response) => {
        let data = response.data; 
        if (data.status == 1) {
          const responseData = response.data.response;
          setDropdownData(responseData);
        }
        backendMessagesController([data]);
      })
      .catch((errors) => {
        catchErrorsController(" Dropdown Data in Vehicle Form --- ", errors);
      }); 
  
    }, []); 
    
    console.log("errors", errors);
 
  const saveHandler = (data) => {   
    // return false;
    console.log("data", data);
    
    Api_PermitOrders.add(data)
    .then((response) => {
      let data = response.data;  
      if (data.status == 1) {
        reset();
        setSelectedRouteStates([]);
        setSelectedRouteCities([]);
        setSelectedVehicleColor([]);
        setSelectedTrailerColor([]); 
        setValue("approx_date_time", ""); 
        for(let i in itemsRefs.current){
          itemsRefs.current[i].resetSelectedValues()
        }
        
        setSuggestionLegalValue("");
        setSearchedLegalData([]); 
        setSuggestionDriverValue("");
        setSearchedDriverData([]); 
        setCityMailingValue("");
        setSearchedCityData([]); 
        setCityMailingValue("");
        setSearchedCityData([]); 
        setCityStartValue("");
        setSearchedCityData([]); 
        setCityEndValue("");
        setSearchedCityData([]); 
        setCityRegisteredOwnerValue("");
        setSearchedCityData([]); 
        setVehicleVinValue("");
        setTrailerVinValue("");
        setSearchedVinData([]);  
        setFreeWaysTags([]);
         
      }
      backendMessagesController([data], backendFieldSpecificErrorHandler);
    })
    .catch((errors) => {
      catchErrorsController("Permit Order Form Submit error ", errors);
    });
  };
   
  const onColorAndStateSelectHandler = (color, type) => {  
    
    if (type == "route_states") {
        let changedStateValues = [];
        color.map((color) => changedStateValues.push(color.name));
        setValue("route_states", changedStateValues);
    } else if (type == "route_cities") {
        let changedStateValues = [];
        color.map((color) => changedStateValues.push(color.name));
        setValue("route_cities", changedStateValues);
        setRouteCityOptionList([]);
    } else if (type == "trailer_color") {
        let changedColorValues = [];
        color.map((color) => changedColorValues.push(color.name));
        setValue("trailer_color", changedColorValues);
    } else if (type == "vehicle_color") {
        let changedColorValues = [];
        color.map((color) => changedColorValues.push(color.name));
        setValue("vehicle_color", changedColorValues);
    } else {
        let changedTrailerColorValues = [];
        color.map((color) => changedTrailerColorValues.push(color.name));
        setValue("color", changedTrailerColorValues);
    }
  };

  const searchKeyupHandler = (typedValue, type="legal_name") => {
    let userInputText = typedValue.value;

    setActiveSuggestionTypeName(type)
 
    if(type == "legal_name"){
      // setSearchLegalBoxText(userInputText);
      console.log("userInputText a", userInputText.length);
      if (userInputText.length >= 3) { 
            Api_AutoSuggestions.getById(userInputText, "companies")
                .then((response) => {
                let data = response.data;  
                console.log("userInputText data", data);
                if (data.status == 1) {
                    const listing_data = data.response.listing_data;
                    setSearchedLegalData(listing_data.length > 0 ? listing_data : []);
                }
                backendMessagesController([data]);
                })
                .catch((errors) => {
                catchErrorsController("Association Company Division ---", errors);
            });
        } else {
            setSearchedLegalData([]);
        }
    }else if(type == "driver_name"){ 
        setSearchDriverBoxText(userInputText);
        if (userInputText.length >= 3) {  
            Api_Driver.getAutoSuggestions(userInputText)
            .then((response) => {
                let data = response.data;
                if (data.status == 1) {
                    const listing_data = data.response.list_data; 
                    setSearchedDriverData(listing_data.length > 0 ? listing_data : []);
                }
                backendMessagesController([data]);
            })
            .catch((errors) => {
                catchErrorsController(
                " Get Vehicle Details Using VIN (permit_order_form.js) --- ",
                errors
                );
            });
        } else {
            setSearchedDriverData([]);
        }
    }
  };

  const autoSuggestionSelectHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
  
    let type = activeSuggestionTypeName;
    // return false;
    if(type == "legal_name"){
        setSearchedLegalData([]); 
        // setSearchLegalBoxText(); 
        Api_Companies.getById(`${suggestion._id}/edit`)
        .then((response) => {
            let data = response.data; 
            console.log("data", data);
            if (data.status == 1) { 
                const company_data = data.response.edit_details;
                setValue("dotco_id", suggestion._id);
                setValue("legal_name", company_data.name);
                setValue("address", company_data.address);
                setValue("city", company_data.city);
                setCityDotcoValue(company_data.city); // for display the city in input field
                setValue("state", company_data.state);
                setValue("zip", company_data.zip_code); 
                setValue("dot_number", company_data.dot_number); 
                setValue("ca_id", company_data.ca_id); 
            }else
            backendMessagesController([data]);
        })
      .catch((errors) => {
        catchErrorsController("Association Company Division ---", errors);
      });
    }else if(type == "driver_name"){
         
      setSearchedDriverData([]);
      setSearchDriverBoxText(); 
      Api_Driver.getById(suggestion.id)
      .then((response) => {
          let data = response.data;      
          if (data.status == 1) { 
              const driver_data = data.response.details;
              setValue("driver_id", suggestion.id);
              setValue("driver_name", suggestion.name);
              setValue("license_number", driver_data.license_number);
              setValue("license_class", driver_data.license_class); 
          }  
          backendMessagesController([data]);
      })
      .catch((errors) => {
          catchErrorsController(
          " Get Vehicle Details Using VIN (permit_order_form.js) --- ",
          errors
          );
      }); 
    }
  };
  
  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => { 
  };
   
  const renderSuggestion = suggestion => { 
    return <div>
      {suggestion.name}
    </div>
  }; 
  
  const getSuggestionValue = (suggestion) => { 
    return suggestion.name;
  };
 
 
  const mailAddressHandler = (data) => { 
    setUseForMailing(data);

    if (data == true) {
      setValue("mail_address", getValues("address"));
      setValue("mail_city", getValues("city"));
      setValue("mail_state", getValues("state"));
      setValue("mail_zip", getValues("zip"));
    } else {
      setValue("mail_address", "");
      setValue("mail_city", "");
      setValue("mail_state", "");
      setValue("mail_zip", "");
    }
  };

  const overWeightFeetInchHandler = (val, type, field) => {  
     
    let inputDimenValue = Number(val.target.value);
    if (type == "feet") {
      let changedIntoInch = inputDimenValue * 12;
      setValue(field, changedIntoInch);
    } else {
      setValue(field, inputDimenValue + Number(getValues(field)));
    } 
  };

  const searchKeyupVinHandler = (val, type) => {
    // console.log(val); 
    let userInputText = val.value;
    setSearchedVinFieldName(type);
    if(userInputText.length == 6){
      Api_FleetStatus.getByParam(
        `get_vehicle_list?partial_vin=${userInputText}`
      )
      .then((response) => {
        let data = response.data;
        console.log("Api_FleetStatus data", data);
        if (data.status == 1) {
          const listingData =data.response.listing_data

          if(listingData.length <= 0){
            /// If vin not found. user input data will add as a vin
            setError(type, {message: "VIN not found!"})  
            // setSearchedVinData(listingData) 
          }else {
            setSearchedVinData(listingData)  
          }
        }   
        backendMessagesController([data], backendFieldSpecificErrorHandler);
      })
      .catch((errors) => {
        catchErrorsController("Fleet Status Get All  --- ", errors);
      });
    }else if(userInputText.length >= 6){ 
      clearErrors(type)
      setValue(type, userInputText) 
      setSearchedVinData([]) ;
      if (type == "vehicle_vin") {
        /////////////////////////////////////////////////////////
        ////////////////// Vehicle Information //////////////////
        ///////////////////////////////////////////////////////// 
        setValue("registered_owner", ""); 
        setValue("vehicle_unit_number", "");
        setValue("vehicle_type", "");
        setValue("vehicle_id", "");
        setValue("vehicle_year", "");
        setValue("vehicle_make", "");
        setValue("vehicle_fuel_type", "");
        setValue("vehicle_license_state", "");
        setValue("vehicle_license_number", "");
        setValue("vehicle_axle_number", "");
        setValue("vehicle_registered_weight_rating", "");
        setSelectedVehicleColor([]);
        setValue("vehicle_color", []);
      } else {
        /////////////////////////////////////////////////////////
        ////////////// Towing Trailer Information ///////////////
        /////////////////////////////////////////////////////////
        setValue("trailer_make", "");
        setValue("trailer_unit_number", "");
        setValue("vehicle_type", "");
        setValue("trailer_year", "");      
        setValue("trailer_license_state", "");
        setValue("trailer_license_number", "");
        setValue("trailer_axle_number", "");
        setValue("trailer_registered_gross_weight_rating", ""); 
        setSelectedTrailerColor([]);
        setValue("trailer_color", []);
      }
    }else {
      setSearchedVinData([]) 
      setVinNotFoundError(false)  
      clearErrors(type)
    }
  };

  const autoSuggestionVinHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {  
      
    
    const vinNumber = suggestion.vin_number;
    const type = searchedVinFieldName;
    console.log("suggestion", suggestion, vinNumber, type);
    // return false; 
    setSearchedVinData([]);
    setValue(type, vinNumber);
    
    Api_Vehicles.getVehicleDetailsByVin(vinNumber)
    .then((response) => { 
      let dataResponse = response.data;  
      console.log("type", type, dataResponse);

        let data = dataResponse.response.edit_details;   
        let status = response.data.status == 1 || response.data.status == "1";
        if (type == "vehicle_vin") {
          /////////////////////////////////////////////////////////
          ////////////////// Vehicle Information //////////////////
          /////////////////////////////////////////////////////////
          if(getValues("is_vehicle_leased") == "yes"){ 
              setValue("registered_owner", status ? data.registered_vendor : "");
          }
          setValue("vehicle_unit_number", status ? data.unit_number : "");
          setValue(
            "vehicle_type",
            status ? data.vehicle_category.toLowerCase() : ""
          );
          setValue("vehicle_id", status ? data._id : "");
          setValue("vehicle_year", status ? data.year : "");
          setValue("vehicle_make", status ? data.make : "");
          setValue("vehicle_fuel_type", status ? data.fuel_type : "");
          setValue("vehicle_license_state", status ? data.license_state : "");
          setValue("vehicle_license_number", status ? data.license_number : "");
          setValue("vehicle_axle_number", status ? data.axle_number : "");
          setValue(
            "vehicle_registered_weight_rating",
            status ? data.registered_gvw.toString() : ""
          );

          let vehicle_color = status
            ? data.color.map((val) => {
                return { value: val, name: val };
              })
            : [];
          setSelectedVehicleColor(vehicle_color);
          setValue("vehicle_color", status ? data.color : "");
        } else {
          /////////////////////////////////////////////////////////
          ////////////// Towing Trailer Information ///////////////
          /////////////////////////////////////////////////////////
          setValue("trailer_make", status ? data.make : "");
          setValue("trailer_unit_number", status ? data.unit_number : "");
          setValue(
            "vehicle_type",
            status ? data.vehicle_category.toLowerCase() : ""
          );
          setValue("trailer_year", status ? data.year : ""); 
          setValue("trailer_license_state", status ? data.license_state : "");
          setValue("trailer_license_number", status ? data.license_number : "");
          setValue("trailer_axle_number", status ? data.axle_number : "");
          setValue(
            "trailer_registered_gross_weight_rating",
            status ? data.registered_gvw.toString() : ""
          );

          let towing_trailer_color = status
            ? data.color.map((val) => {
                return { value: val, name: val };
              })
            : [];
          setSelectedTrailerColor(towing_trailer_color);
          setValue("trailer_color", status ? data.color : []);
        }

        backendMessagesController([dataResponse]);
      })
      .catch((errors) => {
        catchErrorsController(
          " Get Vehicle Details Using VIN (permit_order_form.js) --- ",
          errors
        );
      });
  };
  
  const vinElementHandler = (type)=>{ 
    
    let vinValue = ""
    if(type == "vehicle_vin") {
      vinValue = vehicleVinValue;
    }else if(type == "trailer_vin") {
      vinValue = trailerVinValue;
    } 
 
    return <Autosuggest  
      theme={styles}
      suggestions={searchedVinData}
      onSuggestionsFetchRequested={(event) => searchKeyupVinHandler(event, type)}
      onSuggestionsClearRequested={()=>onSuggestionsClearRequested(type)}
      onSuggestionSelected={autoSuggestionVinHandler}
      getSuggestionValue={(suggestion) => { 
        return suggestion.vin_number;
      }}
      renderSuggestion={(suggestion) => { 
        return <div>
          {suggestion.vin_number}
        </div>
      }}
      highlightFirstSuggestion={true}
      inputProps={{
        placeholder: 'Search by VIN number',
        value: vinValue,
        type: "search ",
        className: "form-control",
        disabled: false,
        onChange: function(event, { newValue }) { 
          if(type == "vehicle_vin") {
            setVehicleVinValue(newValue);
          }else if(type == "trailer_vin") { 
            setTrailerVinValue(newValue);
          } 
        },
      }} 
      containerProps={{style: {maxWidth: "unset"}}}  
    />  
  }

  const selectTripDetailsCloseHandler = useCallback((e)=>{   

    setValue("start_address", e.start_address)
    setValue("start_city", e.start_city)
    setValue("start_state", e.start_state)
    setValue("start_zip", e.start_zip)
    setValue("end_address", e.end_address)
    setValue("end_city", e.end_city);
    setCityStartValue(e.start_state); /// for display in select
    setValue("end_state", e.end_state)
    setCityEndValue(e.end_city); /// for display in select
    setValue("end_zip", e.end_zip)
    setValue("route_states", e.route_states)
    setValue("route_cities", e.route_cities)
    setValue("route_freeways", e.route_freeways) 
  
    setFreeWaysTags(e.route_freeways)/// for display in select
    // Only use to display values into state field
    setSelectedRouteStates(e.route_states?.map((val)=>{return {label:val, name:val}}))
    setSelectedRouteCities(e.route_cities?.map((val)=>{return {label:val, name:val}}))
  })
 
  const searchKeyupCityHandler = (event, type) => {
    let userInputText = event.value;
     
    setActiveSuggestionTypeName(type)
    // setSearchedCityFieldName(type)
    if (userInputText.length >= 3) { 
        Api_AutoSuggestions.getById(userInputText, "cities")
          .then((response) => {
            let data = response.data;  
            if (data.status == 1) {
                const listing_data = data.response.listing_data.map((val)=> { return {_id: val.city, name: val.city}}); 
                setSearchedCityData(listing_data.length > 0 ? listing_data : []);
            }
            backendMessagesController([data]);
          })
        .catch((errors) => {
            catchErrorsController("Association Keyup City Handler ---", errors);
        });
    } else {
        setSearchedCityData([]);
    }
     
  };

  const autoSuggestionCityHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {  
    let type = activeSuggestionTypeName;
    setSearchedCityData([]);  
    setValue(type, suggestion.name);  
  };
   
  const cityElementHandler = (type)=>{ 
     
    return <Autosuggest  
      theme={styles}
      suggestions={searchedCityData}
      onSuggestionsFetchRequested={(event) => searchKeyupCityHandler(event, type)}
      onSuggestionsClearRequested={()=>onSuggestionsClearRequested(type)}
      onSuggestionSelected={autoSuggestionCityHandler}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      highlightFirstSuggestion={true}
      inputProps={{
        placeholder: 'Search by city name',
        value: (type == "city") ?  cityDotcoValue : (type == "mail_city") ? cityMailingValue : (type == "start_city") ? cityStartValue : (type == "end_city") ? cityEndValue : (type == "registered_owner_city") ? cityRegisteredOwnerValue : '',
        type: "search ",
        className: "form-control",
        disabled: false,
        onChange: function(event, { newValue }) { 
          if(type == "city") {
            setCityDotcoValue(newValue);
          }else if(type == "mail_city") {
            setCityMailingValue(newValue);
          }else if(type == "start_city") {
            setCityStartValue(newValue);
          }else if(type == "end_city") {
            setCityEndValue(newValue);
          }else if(type == "registered_owner_city") {
            setCityRegisteredOwnerValue(newValue);
          }
        },
      }} 
      containerProps={{style: {maxWidth: "unset"}}}  
    />  
  }
   
  const routeCityOnSearchHandle = (value) =>{
    let userInputText = value; 

    if (userInputText.length >= 3) { 
        Api_AutoSuggestions.getById(userInputText, "cities")
            .then((response) => {
            let data = response.data; 
            
            if (data.status == 1) {
                const listing_data = data.response.listing_data.map((val)=> { return { value: val.city, name: val.city }}); 
                setRouteCityOptionList(listing_data.length > 0 ? listing_data : []);
            }
            backendMessagesController([data]);
            })
        .catch((errors) => {
            catchErrorsController("Association Company Division ---", errors);
        });
    } else {
        setRouteCityOptionList([]);
    }
  }

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };
  

  const datePicker = (name, type="datetime")=>{
    return <DatePicker
      // {...props} 
      {...register(name)}
      id={name}
      // placeholder={"asdf"}  
        className={`form-control`}
      onChange={(date) =>{ 
        setValue(
          name,
          ChangeDateformat(date, "datetime")
        )       
      }} 
      format='MM/DD/YYYY HH:mm:ss'
      local={{
        ...en,
        lang: {
          ...en.lang,
          fieldDateFormat: 'MM/DD/YYYY',
          fieldDateTimeFormat: 'MM/DD/YYYY HH:mm:ss',
          yearFormat: 'YYYY',
          cellYearFormat: 'YYYY',
        },
      }}
      // defaultValue
      // pickerValue={getValues("approx_date_time")}
      // defaultValue={getValues("approx_date_time") ? dayjs(getValues("approx_date_time"), "'MM/DD/YYYY HH:mm:ss'"): ''}
      // value={getValues("approx_date_time") ? dayjs(getValues("approx_date_time"), "'MM/DD/YYYY HH:mm:ss'"): ''}
      defaultValue={dayjs(new Date().toDateString())}
      showTime  
      allowClear={true} 
    /> 
  }
  
  return (
    <>
      <h1 className="text-center ">Permit Order Form</h1>
      <h4 className="text-center mb-4">
        Please allow a 48-hour notice to process each order.
      </h4>
      <div className="container">
        <CCard>
          <CCardBody>
            <CForm
              className="row" 
              onSubmit={handleSubmit(saveHandler)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <h3>DOTCO Information</h3> 
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="legal_name">Legal Name *</CFormLabel> 
                  <Autosuggest  
                    theme={styles}
                    suggestions={searchedLegalData}
                    onSuggestionsFetchRequested={(event) => searchKeyupHandler(event, "legal_name")}
                    onSuggestionsClearRequested={()=> onSuggestionsClearRequested("legal_name")}
                    onSuggestionSelected={autoSuggestionSelectHandler}
                    getSuggestionValue={getSuggestionValue}
                    highlightFirstSuggestion={true}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                      placeholder: 'Search',
                      value: suggestionLegalValue,
                      type: "search",
                      className: "form-control",
                      disabled: false,
                      onChange: function(event, { newValue }) { 
                        setSuggestionLegalValue(newValue); 
                      },
                    }} 
                    containerProps={{style: {maxWidth: "unset"}}}  
                  /> 
                  <FormFieldError name="legal_name" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="DBA_if_applicable">
                    DBA (if applicable)
                  </CFormLabel>
                  <CFormInput
                    {...register("dba")}
                    default
                    id="DBA_if_applicable"
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="address">Address *</CFormLabel>
                  <CFormInput {...register("address")} default id="address" />
                  <FormFieldError name="address" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="city">City </CFormLabel>
                  {/* <CFormInput {...register("city")} default id="city" /> */}
                  
                  {cityElementHandler("city")}
                  <FormFieldError name="city" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="state">State </CFormLabel>
                  <CFormSelect
                    aria-label="State"
                    {...register("state")}
                    id="state"
                  >
                    <option value="">Select State</option>
                    {dropdownData?.states?.map((ele, inx) => (
                      <option value={ele.abv} key={inx}>{ele.state}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError name="state" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="zip">Zip</CFormLabel>
                  <CFormInput {...register("zip")} default id="zip" 
                    type="text" />
                  <FormFieldError name="zip" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <span className="d-inline-block me-2 fw-bolder">
                    Use for mailing address?{" "}
                  </span>
                <div className="form-check form-check-inline">
                    <input
                        {...register("use_for_mailing")}
                        type="radio"
                        name="use_for_mailing"
                        value="yes"
                        onClick={() => mailAddressHandler(true)}
                        id="is_mailing_address_same_yes"
                        label="Yes" 
                        className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="is_mailing_address_same_yes">
                        Yes
                    </label>
                </div> 
                <div className="form-check form-check-inline">
                    <input
                        {...register("use_for_mailing")}
                        type="radio"
                        name="use_for_mailing"
                        value="no"
                        onClick={() => mailAddressHandler(false)}
                        id="is_mailing_address_same_no"
                        label="No"
                        defaultChecked
                        className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="is_mailing_address_same_no">
                        No
                    </label>
                </div>  
                  <FormFieldError name="use_for_mailing" errors={errors} />
                </CCol>
              </CRow>
              {useForMailing == false && (
                <div className="">
                  <CRow className="mb-3">
                    <CCol>
                      <CFormLabel htmlFor="mailing_address">Mailing Address *</CFormLabel>
                      <CFormInput
                        {...register("mail_address")}
                        id="mailing_address"
                      />
                      <FormFieldError name="mail_address" errors={errors} />
                    </CCol>
                  </CRow>
                  <CRow className="mb-3">
                    <CCol>
                      <CFormLabel htmlFor="mailing_city">City</CFormLabel>
                      {/* <CFormInput
                        {...register("mail_city")}
                        id="mailing_city"
                      /> */}
                      {cityElementHandler("mail_city")}
                      <FormFieldError name="mail_city" errors={errors} />
                    </CCol>
                    <CCol>
                      <CFormLabel htmlFor="mailing_state">State</CFormLabel>
                      <CFormSelect
                        aria-label="Mailing State"
                        {...register("mail_state")}
                        id="mailing_state"
                      >
                        <option value="">Select State</option>
                        {dropdownData?.states?.map((ele, inx) => (
                          <option value={ele.abv} key={inx}>{ele.state}</option>
                        ))}
                      </CFormSelect>
                      <FormFieldError name="mail_state" errors={errors} />
                    </CCol>
                    <CCol>
                      <CFormLabel htmlFor="mailing_zip">Zip</CFormLabel>
                      <CFormInput {...register("mail_zip")} id="mailing_zip" 
                    type="text" />
                      <FormFieldError name="mail_zip" errors={errors} />
                    </CCol>
                  </CRow>
                </div>
              )}
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="fid">FID *</CFormLabel>
                  <CFormInput {...register("fid")} id="fid" />
                  <FormFieldError name="fid" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="ssn">SSN</CFormLabel>
                  <CFormInput {...register("ssn")} id="ssn" />
                  <FormFieldError name="ssn" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="dot_number">DOT # *</CFormLabel>
                  <CFormInput {...register("dot_number")} id="dot_number" />
                  <FormFieldError name="dot_number" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="mc_id">
                    MC # (Motor Carrier Number)
                  </CFormLabel>
                  <CFormInput {...register("mc_id")} id="mc_id" />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="ca_id">
                    CA # (California Number)
                  </CFormLabel>
                  <CFormInput {...register("ca_id")} id="ca_id" />
                  <FormFieldError name="ca_id" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="kyu_id">
                    KYU # (Kentucky Number)
                  </CFormLabel>
                  <CFormInput {...register("kyu_id")} id="kyu_id" />
                  <FormFieldError name="kyu_id" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs="12">
                  <CFormLabel>Business Type: </CFormLabel>
                </CCol>
                <CCol>
                  <CFormCheck
                    {...register("business_type")}
                    id="sole_proprietorship"
                    type="radio"
                    label="Sole Proprietorship"
                    value="proprietorship"
                    onClick={()=>setBusinessTypeRadio("proprietorship")}
                    checked={(businessTypeRadio=="proprietorship") ? true : false}
                    inline
                  />
                  <CFormCheck
                    {...register("business_type")}
                    id="corporation"
                    type="radio"
                    label="Corporation"
                    value="corporation"
                    onClick={()=>setBusinessTypeRadio("corporation")}
                    checked={(businessTypeRadio=="corporation") ? true : false}
                    inline
                  />
                  <CFormCheck
                    {...register("business_type")}
                    id="llc_llp"
                    type="radio"
                    label="LLC/LLP"
                    value="llc_llp"
                    onClick={()=>setBusinessTypeRadio("llc_llp")}
                    checked={(businessTypeRadio=="llc_llp") ? true : false} 
                    inline
                  />
                  <CFormCheck
                    {...register("business_type")}
                    id="partnership"
                    type="radio"
                    label="Partnership"
                    onClick={()=>setBusinessTypeRadio("partnership")}
                    checked={(businessTypeRadio=="partnership") ? true : false}
                    value="partnership"
                    inline
                  />
                  <CFormCheck
                    {...register("business_type")}
                    id="government"
                    type="radio"
                    label="Government"
                    value="government"
                    onClick={()=>setBusinessTypeRadio("government")}
                    checked={(businessTypeRadio=="government") ? true : false}
                    inline
                  />

                  <FormFieldError name="business_type" errors={errors} />
                </CCol>
              </CRow>

              <div className="d-flex justify-content-between align-items-center">
                <h3 className="mt-4">Trip Information</h3>
                <CButton color="primary" onClick={() => setIsTripDetails(true)}>
                  Recent Trip
                </CButton>
              </div> 
              <h5 className="mb-2 fw-bolder">Starting Location</h5>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="start_location_address">
                    Address *
                  </CFormLabel>
                  <CFormInput
                    {...register("start_address")}
                    id="start_location_address"
                  />
                  <FormFieldError name="start_address" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="start_city">City *</CFormLabel> 
                  {cityElementHandler("start_city")}
                  <FormFieldError name="start_city" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="start_location_state">
                    State *
                  </CFormLabel>
                  <CFormSelect
                    aria-label="Trip Start State"
                    {...register("start_state")}
                    id="start_location_state"
                  >
                    <option value={""}>Select State</option>
                    {dropdownData?.states?.map((ele, inx) => (
                      <option value={ele.abv} key={inx}>{ele.state}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError name="start_state" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="start_location_zip">Zip *</CFormLabel>
                  <CFormInput
                    type="text"
                    {...register("start_zip")}
                    id="start_location_zip"
                  />
                  <FormFieldError name="start_zip" errors={errors} />
                </CCol>
              </CRow>
              <h5 className="mb-2 fw-bolder">Ending Location</h5>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="ending_location_address">
                    Address *
                  </CFormLabel>
                  <CFormInput
                    {...register("end_address")}
                    id="ending_location_address"
                  />
                  <FormFieldError name="end_address" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <CFormLabel htmlFor="end_city">City *</CFormLabel> 
                  {cityElementHandler("end_city")}
                  <FormFieldError name="end_city" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="ending_location_state">
                    State *
                  </CFormLabel>
                  <CFormSelect
                    aria-label="Trip End State"
                    {...register("end_state")}
                    id="ending_location_state"
                  >
                    <option value={""}>Select State</option>
                    {dropdownData?.states?.map((ele, inx) => (
                      <option value={ele.abv} key={inx}>{ele.state}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError name="end_state" errors={errors} />
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="ending_location_zip">Zip *</CFormLabel>
                  <CFormInput
                    type="text"
                    {...register("end_zip")}
                    id="ending_location_zip"
                  />
                  <FormFieldError name="end_zip" errors={errors} />
                </CCol>
              </CRow>
              <h5 className="mb-2 fw-bolder">
                What route is the driver taking? (needed for state entry and
                exit points)
              </h5>
              <CRow className="mb-3">
                <CCol xs="12" className="mb-3">
                  <CFormLabel htmlFor="what_route_taking_state">
                    States *
                  </CFormLabel>  
                  <Multiselect
                    options={dropdownData?.states?.map((ele) => {
                      return { value: ele.abv, name: ele.state };
                    })} 
                    selectedValues={selectedRouteStates} 
                    onSelect={(e) =>
                      onColorAndStateSelectHandler(e, "route_states") 
                    }
                    onRemove={(e) =>
                      onColorAndStateSelectHandler(e, "route_states")
                    }  
                    displayValue="name"  
                    showCheckbox={true}
                    avoidHighlightFirstOption={true} 
                    id="vi_route_states"
                    ref={el => itemsRefs.current["route_states"]=el}
                  /> 
                  <FormFieldError name="route_states" errors={errors} />
                </CCol>
                <CCol xs="12" className="mb-3">
                  <CFormLabel htmlFor="vi_route_cities">
                    Cities *
                  </CFormLabel> 
                  <Multiselect
                    options={routeCityOptionList} 
                    selectedValues={selectedRouteCities} 
                    onSelect={(e) =>
                        onColorAndStateSelectHandler(e, "route_cities")
                    } 
                    onRemove={(e) =>
                        onColorAndStateSelectHandler(e, "route_cities")
                    } 
                    displayValue="name"  
                    showCheckbox={true}
                    avoidHighlightFirstOption={true}
                    onSearch={(e)=>routeCityOnSearchHandle(e)} 
                    ref={el => itemsRefs.current["route_cities"]=el}
                    id="vi_route_cities"
                  />
                  
 
                  <FormFieldError name="route_cities" errors={errors} />
                </CCol>
                <CCol xs="12" className="mb-3">
                  <CFormLabel htmlFor="what_route_taking_freeways">
                    Freeways *
                  </CFormLabel>
                  {/* <CFormInput
                    {...register("route_freeways")}
                    id="what_route_taking_freeways"
                  />  */}
                   <TagsInput 
                    value={freeWaysTags}
                    onChange={(val)=>{ 
                      setValue("route_freeways", val)
                      setFreeWaysTags(val) 
                    }}
                    separators={["Enter", ","]} 
                    placeHolder="Enter freeways"
                  />
                  <FormFieldError name="route_freeways" errors={errors} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs="12" className="mb-4"> 
                <CFormLabel htmlFor="approximate_time_and_date" className="me-3">Trip Type? *</CFormLabel>
                  <CFormCheck
                    {...register("trip_type")}
                    type="radio" 
                    id="is_initial_trip"
                    value="initial_trip"
                    label="Initial Trip"
                    onClick={()=>setTripTypeRadio("initial_trip")}
                    checked={(tripTypeRadio=="initial_trip") ? true : false} 
                    inline
                  />
                  <CFormCheck
                    {...register("trip_type")}
                    type="radio" 
                    id="is_return_trip"
                    value="return_trip"
                    label=" Return Trip?"
                    onClick={()=>setTripTypeRadio("return_trip")}
                    checked={(tripTypeRadio=="return_trip") ? true : false}
                    inline
                  />
                  <CFormCheck
                    {...register("trip_type")}
                    type="radio" 
                    id="is_both"
                    value="both"
                    label="Both?"
                    onClick={()=>setTripTypeRadio("both")}
                    checked={(tripTypeRadio=="both") ? true : false}
                    inline
                  />
                  <FormFieldError name="trip_type" errors={errors} />
                </CCol>
                <CCol xs={4} className="mb-3">
                  <CFormLabel htmlFor="approximate_time_and_date">
                    Approximate time and date of departure *
                  </CFormLabel> 
                    {datePicker("approx_date_time", "s")}
                  <FormFieldError name="approx_date_time" errors={errors} />
                </CCol>
                <CCol xl={4} className="mb-3">
                  <CFormLabel htmlFor="approximate_miles_driven">
                    Approximate miles driven within the state *
                  </CFormLabel>
                  <CFormInput
                    {...register("approx_miles")}
                    id="approximate_miles_driven"
                  />
                  <FormFieldError name="approx_miles" errors={errors} />
                </CCol>
                <CCol xl={4}></CCol> 
              </CRow>
              {(tripTypeRadio == "both" || tripTypeRadio == "return_trip")  && 
              <CRow> 
                <CCol xl={4}>
                  <CFormLabel htmlFor="approx_return_date_time">
                    Approximate time and Date of return *
                  </CFormLabel>
                  {datePicker("approx_return_date_time", "s")}
                  <FormFieldError name="approx_return_date_time" errors={errors} />
                </CCol>  
                <CCol xl={4} className="mb-3">
                  <CFormLabel htmlFor="approx_miles_return">
                    Approximate miles driven within the state *
                  </CFormLabel>
                  <CFormInput
                    {...register("approx_miles_return")}
                    id="approx_miles_return"
                  />
                  <FormFieldError name="approx_miles_return" errors={errors} />
                </CCol>
              </CRow>
              }
              <h3 className="mt-4 mb-2">Driver Information</h3>
              <CRow className="mb-3">
                <CCol className="mb-3">
                  <CFormLabel htmlFor="di_name">Name *</CFormLabel>
                  <div className={styles.autosuggestion}>
                    <Autosuggest  
                      theme={styles}
                      suggestions={searchedDriverData}
                      onSuggestionsFetchRequested={(event) => searchKeyupHandler(event, "driver_name")}
                      onSuggestionsClearRequested={()=>onSuggestionsClearRequested("driver_name")}
                      onSuggestionSelected={autoSuggestionSelectHandler}
                      getSuggestionValue={getSuggestionValue}
                      highlightFirstSuggestion={true}
                      renderSuggestion={renderSuggestion}
                      inputProps={{
                        placeholder: 'Search by driver name',
                        value: suggestionDriverValue,
                        type: "search",
                        className: "form-control",
                        disabled: false,
                        onChange: function(event, { newValue }) { 
                          setSuggestionDriverValue(newValue); 
                        },
                      }} 
                      containerProps={{style: {maxWidth: "unset"}}}  
                    />
       
                </div>
                  <FormFieldError name="driver_name" errors={errors} />
                </CCol>
                <CCol className="mb-3">
                  <CFormLabel htmlFor="di_license">License # *</CFormLabel>
                  <CFormInput {...register("license_number")} id="di_license" />
                  <FormFieldError name="license_number" errors={errors} />
                </CCol>
                <CCol className="mb-3">
                  <CFormLabel htmlFor="di_license_class">
                    License Class *
                  </CFormLabel>
                  <CFormInput
                    {...register("license_class")}
                    id="di_license_class"
                  />
                  <FormFieldError name="license_class" errors={errors} />
                </CCol>
              </CRow>
              <h3 className="mt-4 mb-2">Insurance Information</h3>
              <CRow className="mb-3">
                <CCol className="mb-3">
                  <CFormLabel htmlFor="ii_carrier">Carrier *</CFormLabel>
                  <CFormInput {...register("carrier")} id="ii_carrier" />
                  <FormFieldError name="carrier" errors={errors} />
                </CCol>
                <CCol className="mb-3">
                  <CFormLabel htmlFor="ii_policy">Policy # *</CFormLabel>
                  <CFormInput {...register("policy_number")} id="ii_policy" />
                  <FormFieldError name="policy_number" errors={errors} />
                </CCol>
                <CCol className="mb-3">
                  <CFormLabel htmlFor="ii_expiration_date">
                    Expiration Date *
                  </CFormLabel> 
                    <DatePicker
                      // {...props}
                      // ref={inputRef}
                      {...register("expiration_date")}
                      id="expiration_date"
                      // placeholder={"asdf"}  
                      className={`form-control`}
                      onChange={(date) =>{ 
                        setValue(
                          "expiration_date",
                          ChangeDateformat(date)
                        )      
                      }} 
                      format='MM/DD/YYYY'
                      // showTime 
                      popupStyle={{zIndex:10000}}
                    /> 
                  <FormFieldError name="expiration_date" errors={errors} />
                </CCol>
              </CRow>

              <h3 className="mt-4">Vehicle Information</h3>  
              <CRow className="mb-3">
                <CCol>
                  <span className="d-inline-block me-2 fw-bolder">
                    Is this vehicle a Leased vehicle?{" "}
                  </span>
                <div className="form-check form-check-inline">
                    <input
                        {...register("is_vehicle_leased")}
                        type="radio"
                        name="is_vehicle_leased"
                        className="form-check-input"
                        value="yes"
                        onClick={() => setIsVehicleLeased(true)}
                        id="is_vehicle_leased_yes"
                        label="Yes" 
                    />
                    <label className="form-check-label" htmlFor="is_vehicle_leased_yes">
                        Yes
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        {...register("is_vehicle_leased")}
                        type="radio"
                        name="is_vehicle_leased"
                        className="form-check-input"
                        value="no"
                        onClick={() => setIsVehicleLeased(false)}
                        id="is_vehicle_leased_no"
                        label="No"
                        defaultChecked 
                    />
                    <label className="form-check-label" htmlFor="is_vehicle_leased_no">
                        No
                    </label>
                </div>
                  
                  
                  <FormFieldError name="is_vehicle_leased" errors={errors} />
                </CCol>
              </CRow>
              <CRow>
                {isVehicleLeased && (
                  <>
                    <CCol className="mb-3" xs="5">
                      <CFormLabel htmlFor="vi_registered_owner">
                        Registered Owner (if leased/rented) *
                      </CFormLabel>
                      <CFormInput
                        {...register("registered_owner")}
                        id="vi_registered_owner"
                      />
                      <FormFieldError name="registered_owner" errors={errors} />
                    </CCol>
                    <CCol className="mb-3" xs="7">
                      <CFormLabel htmlFor="vi_registered_owner_address">
                        Registered Owner Address *
                      </CFormLabel>
                      <CFormInput
                        {...register("registered_owner_address")}
                        id="vi_registered_owner_address"
                      />
                      <FormFieldError
                        name="registered_owner_address"
                        errors={errors}
                      />
                    </CCol>
                  </>
                )}
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_city">City</CFormLabel>
                  {/* <CFormInput
                    {...register("registered_owner_city")}
                    id="vi_city"
                  /> */}
                  {cityElementHandler("registered_owner_city")}
                  <FormFieldError
                    name="registered_owner_city"
                    errors={errors}
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_state">State</CFormLabel>
                  <CFormSelect
                    aria-label="Registered Owner State"
                    {...register("registered_owner_state")}
                    id="vi_state"
                  >
                    <option value="">Select State</option>
                    {dropdownData?.states?.map((ele, inx) => (
                      <option value={ele.abv} key={inx}>{ele.state}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError
                    name="registered_owner_state"
                    errors={errors}
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_zip">Zip</CFormLabel>
                  <CFormInput
                    type="text"
                    {...register("registered_owner_zip")}
                    id="vi_zip"
                  />
                  <FormFieldError name="registered_owner_zip" errors={errors} />
                </CCol>
                
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_vin">VIN *</CFormLabel> 
                  {vinElementHandler("vehicle_vin")}
                   
                  <FormFieldError name="vehicle_vin" errors={errors} />
                  {
                    vinNotFoundError && <span className="text-danger mt-1">VIN not found!</span>
                  }
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_year">Year *</CFormLabel>
                  <CFormInput {...register("vehicle_year")} id="vi_year" />
                  <FormFieldError name="vehicle_year" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_make">Make</CFormLabel>
                  <CFormInput {...register("vehicle_make")} id="vi_make" />
                  <FormFieldError name="vehicle_make" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_vehicle_type">
                    Vehicle Type *
                  </CFormLabel>
                  <CFormSelect
                    {...register("vehicle_type")}
                    id="vi_vehicle_type" 
                  >
                    <option value={""}>Select</option>
                    {
                        dropdownData?.permit_form_vehicle_types?.map((val, inx)=> <option value={val.type.toLowerCase()} key={inx}>{val.type}</option>)
                    } 
                    </CFormSelect>
                  <FormFieldError name="vehicle_type" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_unit_number">
                    Unit Number *
                  </CFormLabel>
                  <CFormInput
                    {...register("vehicle_unit_number")}
                    id="vi_unit_number"
                  />
                  <FormFieldError name="vehicle_unit_number" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_license_plate">
                    License Plate *
                  </CFormLabel>
                  <CFormInput
                    {...register("vehicle_license_number")}
                    id="vi_license_plate"
                  />
                  <FormFieldError
                    name="vehicle_license_number"
                    errors={errors}
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_license_state">
                    License State *
                  </CFormLabel>
                  <CFormSelect
                    {...register("vehicle_license_state")}
                    id="vi_license_state"
                  >
                    <option value="">Select State</option>
                    {dropdownData?.states?.map((ele, inx) => (
                      <option value={ele.abv} key={inx}>{ele.state}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError
                    name="vehicle_license_state"
                    errors={errors} 
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_license_expiration_date">
                    License Plate Expiration Date *
                  </CFormLabel> 
 
                    <DatePicker
                      // {...props}
                      // ref={inputRef}
                      {...register("vehicle_lic_exp_date")}
                      id="vi_license_expiration_date"
                      // placeholder={"asdf"}  
                        className={`form-control`}
                      onChange={(date) =>{ 
                        setValue(
                          "vehicle_lic_exp_date",
                          ChangeDateformat(date)
                        )      
                      }} 
                      format='MM/DD/YYYY'
                      // showTime 
                      popupStyle={{zIndex:10000}}
                    /> 
                  <FormFieldError
                    name="vehicle_lic_exp_date"
                    errors={errors}
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_axles">Axles</CFormLabel>
                  <CFormInput
                    {...register("vehicle_axle_number")}
                    id="vi_axles"
                  />
                  <FormFieldError name="vehicle_axle_number" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_fuel_type">Fuel Type </CFormLabel>
                  <CFormSelect
                    {...register("vehicle_fuel_type")}
                    id="vi_fuel_type"
                  >
                    <option value="">Select Fuel Type</option>
                    {dropdownData?.vehicle_fuel_types?.map((ele, inx) => (
                      <option value={ele} key={inx}>{ele}</option>
                    ))}
                  </CFormSelect>
                  <FormFieldError name="vehicle_fuel_type" errors={errors} />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_weight_rating">
                    Registered Weight Rating *
                  </CFormLabel>
                  <CFormInput
                    {...register("vehicle_registered_weight_rating")}
                    id="vi_weight_rating"
                  />
                  <FormFieldError
                    name="vehicle_registered_weight_rating"
                    errors={errors}
                  />
                </CCol>
                <CCol className="mb-3" xs="4">
                  <CFormLabel htmlFor="vi_vehicle_color">
                    Vehicle Color
                  </CFormLabel>
                  <Multiselect
                    options={dropdownData?.vehicle_colors?.map((ele) => {
                      return { value: ele, name: ele };
                    })} // Options to display in the dropdown
                    selectedValues={selectedVehicleColor} // Preselected value to persist in dropdown
                    ref={el => itemsRefs.current["vehicle_color"]=el}
                    onSelect={(e) =>
                      onColorAndStateSelectHandler(e, "vehicle_color")
                    } // Function will trigger on select event
                    onRemove={(e) =>
                      onColorAndStateSelectHandler(e, "vehicle_color")
                    } // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    // singleSelect={!isVehicleColorMulticolor}
                    showCheckbox={true}
                    avoidHighlightFirstOption={true}
                    // disablePreSelectedValues={true}
                    // style={multiSelectStyle}
                    id="vi_vehicle_color"
                  />
                  <FormFieldError
                    name="vehicle_color"
                    errors={errors}
                  />
                </CCol> 
                <CCol>
                  <span className="d-inline-block me-2 fw-bolder">
                    Automotive Fuel Hauler?{" "}
                  </span>
                    <div className="form-check form-check-inline">
                        <input
                            {...register("automotive_fuel_hauler")}
                            type="radio" 
                            value="yes" 
                            id="automotive_fuel_hauler_yes"
                            label="Yes"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="automotive_fuel_hauler_yes">
                            Yes
                        </label>
                    </div> 
                    <div className="form-check form-check-inline">
                        <input
                            {...register("automotive_fuel_hauler")}
                            type="radio" 
                            value="no" 
                            id="automotive_fuel_hauler_no"
                            label="No"
                            defaultChecked
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="automotive_fuel_hauler_no">
                            No
                        </label>
                    </div>   
                  <FormFieldError
                    name="is_vehicautomotive_fuel_haulerle_leased"
                    errors={errors}
                  />
                </CCol>
              </CRow>


              <CRow>
                <CCol xs="12" className="mb-2 ">
                  <h3 className="mt-4 mb-3">Towing Trailer</h3>
                  <span className="d-inline-block me-2 fw-bolder">
                    Will this vehicle be towing a Trailer?{" "}
                  </span> 
                    <div className="form-check form-check-inline">
                        <input
                            {...register("towing_trailer")}
                            type="radio"
                            name="towing_trailer"
                            value="yes"
                            onClick={() => setIsTowingTrailer(true)}
                            id="towing_trailer_yes"
                            label="Yes"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="towing_trailer_yes">
                            Yes
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            {...register("towing_trailer")}
                            type="radio"
                            name="towing_trailer"
                            value="no"
                            onClick={() => setIsTowingTrailer(false)}
                            id="towing_trailer_no"
                            label="No"
                            defaultChecked
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="towing_trailer_no">
                            No
                        </label>
                    </div> 
                  <FormFieldError name="towing_trailer" errors={errors} />
                </CCol>
              </CRow>
              {isTowingTrailer && (
                <CRow>
                  
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_vin">VIN *</CFormLabel>
                    {vinElementHandler("trailer_vin")} 
                    <FormFieldError name="trailer_vin" errors={errors} />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_year">Year *</CFormLabel>
                    <CFormInput
                      {...register("trailer_year")}
                      id="towing_trailer_year"
                    />
                    <FormFieldError name="trailer_year" errors={errors} />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_make">Make</CFormLabel>
                    <CFormInput
                      {...register("trailer_make")}
                      id="towing_trailer_make"
                    />
                    <FormFieldError name="trailer_make" errors={errors} />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_unit_number">
                      Unit Number *
                    </CFormLabel>
                    <CFormInput
                      {...register("trailer_unit_number")}
                      id="towing_trailer_unit_number"
                    />
                    <FormFieldError
                      name="trailer_unit_number"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_license_plate">
                      License Plate *
                    </CFormLabel>
                    <CFormInput
                      {...register("trailer_license_number")}
                      id="towing_trailer_license_plate"
                    />
                    <FormFieldError
                      name="trailer_license_number"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_license_state">
                      License State *
                    </CFormLabel>
                    <CFormSelect
                      aria-label="Towing Trailer License State"
                      {...register("trailer_license_state")}
                      id="towing_trailer_license_state"
                    >
                      <option value={""}>Select State</option>
                      {dropdownData?.states?.map((ele, inx) => (
                        <option value={ele.abv} key={inx}>{ele.state}</option>
                      ))}
                    </CFormSelect>
                    <FormFieldError
                      name="trailer_license_state"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_weight_rating">
                      Registered Gross Weight Rating
                    </CFormLabel>
                    <CFormInput
                      {...register("trailer_registered_gross_weight_rating")}
                      id="towing_trailer_weight_rating"
                    />
                    <FormFieldError
                      name="trailer_registered_gross_weight_rating"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_axles">
                      Axles *
                    </CFormLabel>
                    <CFormInput
                      {...register("trailer_axle_number")}
                      id="towing_trailer_axles"
                    />
                    <FormFieldError
                      name="trailer_axle_number"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="towing_trailer_empty_weight">
                      Empty Weight (optional)
                    </CFormLabel>
                    <CFormInput
                      {...register("trailer_empty_weight")}
                      id="towing_trailer_empty_weight"
                    />
                    <FormFieldError
                      name="trailer_empty_weight"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="trailer_color">
                      Trailer Color
                    </CFormLabel>
                    <Multiselect
                      options={dropdownData?.vehicle_colors?.map((ele) => {
                        return { value: ele, name: ele };
                      })} 
                      selectedValues={selectedTrailerColor}  
                      ref={el => itemsRefs.current["trailer_color"]=el}
                      onSelect={(e) =>
                        onColorAndStateSelectHandler(e, "trailer_color")
                      } 
                      onRemove={(e) =>
                        onColorAndStateSelectHandler(e, "trailer_color")
                      }  
                      displayValue="name"  
                      showCheckbox={true}
                      avoidHighlightFirstOption={true} 
                      id="towing_trailer_color"
                    />
                    <FormFieldError name="trailer_color" errors={errors} /> 
                  </CCol>
                </CRow>
              )}
 
              <h3 className="mt-4 mb-2">Overweight / Over dimension</h3>
              <CRow>
                <CCol>
                  <span className="d-inline-block me-2 fw-bolder">
                    Is this vehicle Overweight or Over Dimensional?{" "}
                  </span> 
                    <div className="form-check form-check-inline">
                        <input
                            {...register("is_vehicle_overweight")}
                            type="radio"
                            name="is_vehicle_overweight"
                            value="yes"
                            onClick={() => setIsOverweightOrDimensional(true)}
                            id="is_vehicle_overweight_yes"
                            label="Yes"
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="is_vehicle_overweight_yes">
                            Yes
                        </label>
                    </div>  
                    <div className="form-check form-check-inline">
                        <input
                            {...register("is_vehicle_overweight")}
                            type="radio"
                            name="is_vehicle_overweight"
                            value="no"
                            onClick={() => setIsOverweightOrDimensional(false)}
                            id="is_vehicle_overweight_no"
                            label="No"
                            defaultChecked
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="is_vehicle_overweight_no">
                            No
                        </label>
                    </div>   
                  <FormFieldError
                    name="is_vehicle_overweight"
                    errors={errors}
                  />
                </CCol>
              </CRow>
              {isOverweightOrDimensional && (
                <CRow>
                  <CCol xl="12" className="mb-3">
                    <p className="mt-3 mb-1">*Disregard is not OW/OD order*</p>
                    <h6 className="mb-1">
                      Please see required Information regarding OW/OD order
                      below:
                    </h6>
                    <p>
                      *Document accompanying freight that states the agreement
                      between the shipper and carrier and governs their
                      relationship when goods are transported.
                    </p>
                    <h5 className="mb-1">
                      Load Info:
                    </h5>
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="bill_of_lading">
                      Bill of Lading
                    </CFormLabel>
                    <CFormInput
                      {...register("bill_of_lading")}
                      id="bill_of_lading"
                    />
                    <FormFieldError name="bill_of_lading" errors={errors} />
                  </CCol>
                  <CCol xl="12" className="mb-3">
                    <CFormLabel htmlFor="bill_description">
                      Description
                    </CFormLabel>
                    <CFormInput
                      {...register("bill_description")}
                      id="bill_description"
                    />
                    <FormFieldError name="bill_description" errors={errors} />
                  </CCol>
                  <CCol xl="6" className="mb-3">
                    <CFormLabel htmlFor="load_weight">Load Weight:</CFormLabel>
                    <CFormInput {...register("load_weight")} id="load_weight" />
                    <FormFieldError name="load_weight" errors={errors} />
                  </CCol>
                  <CCol xl="6" className="mb-3">
                    <CFormLabel htmlFor="overall_gvw">Overall GVW:</CFormLabel>
                    <CFormInput {...register("overall_gvw")} id="overall_gvw" />
                    <FormFieldError name="overall_gvw" errors={errors} />
                  </CCol>
                  <CCol xl="12" className="mb-1 mt-3">
                    <h5 className="mb-2 fw-bolder">Load Dimensions:</h5>{" "}
                    <hr
                      style={{
                        height: "3px",
                        borderWidth: 0,
                        color: "gray",
                        backgroundColor: "gray",
                      }}
                    />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="load_dimen_length_feet">
                      Length 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("load_dimen_length_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "load_dimen_length"
                          )
                        }
                        id="load_dimen_length_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("load_dimen_length_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "load_dimen_length"
                          )
                        }
                        id="load_dimen_length_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.load_dimen_length_feet ? (
                      <FormFieldError
                        name="load_dimen_length_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="load_dimen_length_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="load_dimen_length" errors={errors} />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="load_dimen_width_feet">
                      Width 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("load_dimen_width_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "load_dimen_width"
                          )
                        }
                        id="load_dimen_width_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("load_dimen_width_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "load_dimen_width"
                          )
                        }
                        id="load_dimen_width_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.load_dimen_width_feet ? (
                      <FormFieldError
                        name="load_dimen_width_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="load_dimen_width_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="load_dimen_width" errors={errors} />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Height 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("load_dimen_height_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "load_dimen_height"
                          )
                        }
                        id="load_dimen_height_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("load_dimen_height_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "load_dimen_height"
                          )
                        }
                        id="load_dimen_height_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.load_dimen_height_feet ? (
                      <FormFieldError
                        name="load_dimen_height_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="load_dimen_height_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="load_dimen_height" errors={errors} />
                  </CCol>
                  <CCol xl="12" className="mb-1 mt-3">
                    <h5 className="mb-2 fw-bolder">Overall Dimensions:</h5>{" "}
                    <hr
                      style={{
                        height: "3px",
                        borderWidth: 0,
                        color: "gray",
                        backgroundColor: "gray",
                      }}
                    />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Length 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("overall_dimen_length_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "overall_dimen_length"
                          )
                        }
                        id="overall_dimen_length_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("overall_dimen_length_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "overall_dimen_length"
                          )
                        }
                        id="overall_dimen_length_inch" 
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.overall_dimen_length_feet ? (
                      <FormFieldError
                        name="overall_dimen_length_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="overall_dimen_length_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="overall_dimen_length" errors={errors} />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Width 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("overall_dimen_width_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "overall_dimen_width"
                          )
                        }
                        id="overall_dimen_width_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("overall_dimen_width_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "overall_dimen_width"
                          )
                        }
                        id="overall_dimen_width_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.overall_dimen_width_feet ? (
                      <FormFieldError
                        name="overall_dimen_width_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="overall_dimen_width_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="overall_dimen_width" errors={errors} />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Height 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("overall_dimen_height_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "overall_dimen_height"
                          )
                        }
                        id="overall_dimen_height_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("overall_dimen_height_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "overall_dimen_height"
                          )
                        }
                        id="overall_dimen_height_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.overall_dimen_height_feet ? (
                      <FormFieldError
                        name="overall_dimen_height_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="overall_dimen_height_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="overall_dimen_height" errors={errors} />
                  </CCol>

                  <CCol xl="12" className="mb-1 mt-3">
                    <h5 className="mb-2 fw-bolder">Overhang:</h5>{" "}
                    <hr
                      style={{
                        height: "3px",
                        borderWidth: 0,
                        color: "gray",
                        backgroundColor: "gray",
                      }}
                    />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Front 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("overhang_front_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "overhang_front"
                          )
                        }
                        id="overhang_front_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("overhang_front_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "overhang_front"
                          )
                        }
                        id="overhang_front_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.overhang_front_feet ? (
                      <FormFieldError
                        name="overhang_front_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="overhang_front_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="overhang_front" errors={errors} />
                  </CCol>
                  <CCol xl="4">
                    <CFormLabel htmlFor="">
                      Rear 
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("overhang_rear_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "overhang_rear"
                          )
                        }
                        id="overhang_rear_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("overhang_rear_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "overhang_rear"
                          )
                        }
                        id="overhang_rear_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.overhang_rear_feet ? (
                      <FormFieldError
                        name="overhang_rear_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="overhang_rear_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="overhang_rear" errors={errors} />
                  </CCol>

                  <CCol xl="12" className="mb-1 mt-3">
                    <h5 className="mb-2 fw-bolder">Axle Information:</h5>{" "}
                    <hr
                      style={{
                        height: "3px",
                        borderWidth: 0,
                        color: "gray",
                        backgroundColor: "gray",
                      }}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="">
                      Axle Count
                    </CFormLabel>
                    <CFormInput {...register("axle_count")} id="axle_count" />
                    <FormFieldError name="axle_count" errors={errors} />
                  </CCol>
                  <CCol xl="8"></CCol>
                  <CCol xs="12">
                  <CFormLabel htmlFor="">
                      Axle Spacing
                    </CFormLabel> 
                    <p>*If more space is needed, please provide that information in the Notes section. </p>
                  </CCol>
                  <CCol xl="3" className="mb-3">
                    <CFormLabel htmlFor="">
                      1.
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("axle_spacing_1_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "axle_spacing_1"
                          )
                        }
                        id="axle_spacing_1_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("axle_spacing_1_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "axle_spacing_1"
                          )
                        }
                        id="axle_spacing_1_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.axle_spacing_1_feet ? (
                      <FormFieldError
                        name="axle_spacing_1_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="axle_spacing_1_inch"
                        errors={errors}
                      />
                    )} 
                    <FormFieldError name="axle_spacing_1" errors={errors} />
                  </CCol>
                  <CCol xl="3" className="mb-3">
                    <CFormLabel htmlFor="">
                      2.
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("axle_spacing_2_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "axle_spacing_2"
                          )
                        }
                        id="axle_spacing_2_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("axle_spacing_2_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "axle_spacing_2"
                          )
                        }
                        id="axle_spacing_2_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.axle_spacing_2_feet ? (
                      <FormFieldError
                        name="axle_spacing_2_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="axle_spacing_2_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="axle_spacing_2" errors={errors} />
                  </CCol>
                  <CCol xl="3" className="mb-3">
                    <CFormLabel htmlFor="">
                      3.
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("axle_spacing_3_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "axle_spacing_3"
                          )
                        }
                        id="axle_spacing_3_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("axle_spacing_3_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "axle_spacing_3"
                          )
                        }
                        id="axle_spacing_3_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.axle_spacing_3_feet ? (
                      <FormFieldError
                        name="axle_spacing_3_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="axle_spacing_3_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="axle_spacing_3" errors={errors} />
                  </CCol>
                  <CCol xl="3" className="mb-3">
                    <CFormLabel htmlFor="">
                      4.
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("axle_spacing_4_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "axle_spacing_4"
                          )
                        }
                        id="axle_spacing_4_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("axle_spacing_4_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "axle_spacing_4"
                          )
                        }
                        id="axle_spacing_4_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.axle_spacing_4_feet ? (
                      <FormFieldError
                        name="axle_spacing_4_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="axle_spacing_4_inch"
                        errors={errors}
                      />
                    )} 
                    <FormFieldError name="axle_spacing_4" errors={errors} />
                  </CCol> 
                  <CCol xl="12">
                    <CFormLabel htmlFor="">
                      Axle Weight (lbs):
                    </CFormLabel>
                    <CRow>
                      <CCol className="mb-3">
                        <CFormInput {...register("axle_weight_1")} id="" />
                        <FormFieldError name="axle_weight_1" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput {...register("axle_weight_2")} id="" />
                        <FormFieldError name="axle_weight_2" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput {...register("axle_weight_3")} id="" />
                        <FormFieldError name="axle_weight_3" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput {...register("axle_weight_4")} id="" />
                        <FormFieldError name="axle_weight_4" errors={errors} />
                      </CCol>
                    </CRow>
                    <FormFieldError
                      name="is_vehicle_overweight"
                      errors={errors}
                    />
                  </CCol>
                  <CCol xl="12">
                    <CFormLabel htmlFor="">
                      Axle Tire Width (in):
                    </CFormLabel>
                    <CRow>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_width_1")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_width_1" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_width_2")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_width_2" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_width_3")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_width_3" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_width_4")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_width_4" errors={errors} />
                      </CCol>
                    </CRow>
                    <FormFieldError
                      name="is_vehicle_overweight"
                      errors={errors}
                    />
                  </CCol>

                  <CCol xl="12">
                    <CFormLabel htmlFor="">
                      Axle Tire Count:
                    </CFormLabel>
                    <p>*per axle*</p>
                    <CRow>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_count_1")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_count_1" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_count_2")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_count_2" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_count_3")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_count_3" errors={errors} />
                      </CCol>
                      <CCol className="mb-3">
                        <CFormInput
                          {...register("axle_tire_count_4")}
                          id=""
                        />
                        <FormFieldError name="axle_tire_count_4" errors={errors} />
                      </CCol>
                    </CRow>
                    <FormFieldError
                      name="is_vehicle_overweight"
                      errors={errors}
                    />
                  </CCol>

                  <CCol xl="12" className="mb-1 mt-3">
                    <h5 className="mb-2 fw-bolder">Trailer Dimensions:</h5>{" "}
                    <hr
                      style={{
                        height: "3px",
                        borderWidth: 0,
                        color: "gray",
                        backgroundColor: "gray",
                      }}
                    />
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="">
                      Length
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("trailer_dimen_length_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "trailer_dimen_length"
                          )
                        }
                        id="trailer_dimen_length_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("trailer_dimen_length_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "trailer_dimen_length"
                          )
                        }
                        id="trailer_dimen_length_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.trailer_dimen_length_feet ? (
                      <FormFieldError
                        name="trailer_dimen_length_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="trailer_dimen_length_inch"
                        errors={errors}
                      />
                    )}
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="">
                      Width
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("trailer_dimen_width_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "trailer_dimen_width"
                          )
                        }
                        id="trailer_dimen_width_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("trailer_dimen_width_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "trailer_dimen_width"
                          )
                        }
                        id="trailer_dimen_width_inch"
                        placeholder="(in)"
                        type="number" 
                      />
                    </CInputGroup>
                    {errors.trailer_dimen_width_feet ? (
                      <FormFieldError
                        name="trailer_dimen_width_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="trailer_dimen_width_inch"
                        errors={errors}
                      />
                    )}
                  </CCol>
                  <CCol xl="4" className="mb-3">
                    <CFormLabel htmlFor="">
                      Height
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        {...register("trailer_dimen_height_feet")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "feet",
                            "trailer_dimen_height"
                          )
                        }
                        id="trailer_dimen_height_feet"
                        placeholder="(ft)"
                      />
                      <CFormInput
                        {...register("trailer_dimen_height_inch")}
                        onChange={(val) =>
                          overWeightFeetInchHandler(
                            val,
                            "inch",
                            "trailer_dimen_height"
                          )
                        }
                        id="trailer_dimen_height_inch"
                        placeholder="(in)"
                        type="number"
                      />
                    </CInputGroup>
                    {errors.trailer_dimen_height_feet ? (
                      <FormFieldError
                        name="trailer_dimen_height_feet"
                        errors={errors}
                      />
                    ) : (
                      <FormFieldError
                        name="trailer_dimen_height_inch"
                        errors={errors}
                      />
                    )}
                    <FormFieldError name="trailer_dimen_height" errors={errors} />
                  </CCol>
                </CRow>
              )}

              <CRow>
                <CCol xl="4" className="mb-5 mt-3">
                  <CFormLabel htmlFor="irp_number">
                    IRP Number (International Registration Plan, if applicable)
                  </CFormLabel>
                  <CFormInput {...register("irp_number")} id="irp_number" />
                </CCol>
              </CRow>

                <hr className="mt-3" />
              <div className="d-flex justify-content-center">
              <CButton
                // onClick={() => handleSubmit(saveHandler)()}
                color="primary"
                size="lg"
                type="submit"
              >
                Submit
              </CButton>

              </div>
            </CForm>

            <PixelTracking />
          </CCardBody>
        </CCard>
      </div>
      <TripDetailsComponent
        isTripDetails={isTripDetails}
        tripDetailsCloseHandler={useCallback((e) => setIsTripDetails(e))}
        state={dropdownData?.states}
        selectTripDetailsHandler={selectTripDetailsCloseHandler}
      />
    </>
  );
};





const TripDetailsComponent = memo(function TripDetailsComponent({
  isTripDetails,
  tripDetailsCloseHandler,
  selectTripDetailsHandler
}) {
  const formValidationSchema = yup.object().shape({
    start_city: yup
      .string().when("start_zip", {
        is: (val) => val =="" || val ==null,
        then: yup.string().required("Please enter one of any field (State or Zip)")}), 
    end_city: yup
      .string().when("end_zip", {
        is: (val) => val =="" || val ==null,
        then: yup.string().required("Please enter one of any field (State or Zip)")}),  
  });
  const {
    register,
    getFieldState,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValue: {},
    resolver: yupResolver(formValidationSchema),
  });
  const { catchErrorsController, backendMessagesController } =
    useApiMessageHandler();
  const Api_PermitOrders = ApiPermitOrders();
  const Api_AutoSuggestions = ApiAutoSuggestions();
  const [tripData, setTripData] = useState([]);
  const [searchedCityData, setSearchedCityData] = useState([]);
  const [searchedCityFieldName, setSearchedCityFieldName] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [cityStartValue, setCityStartValue] = useState("");
  const [cityEndValue, setCityEndValue] = useState("");
  const [activeSuggestionTypeName, setActiveSuggestionTypeName] = useState('');

  const getDataHandler = (formData) => {
    let urlPara = `trip_details/?start_city=${formData.start_city}&start_zip=${formData.start_zip}&end_city=${formData.end_city}&end_zip=${formData.end_zip}`;
    Api_PermitOrders.getByCustomUrl(urlPara)
      .then((response) => { 
        let data = response.data;
        console.log(data);
        
        if(data.status == 1){  
          if(Array.isArray(data.response.list_data) && data.response.list_data.length) {
            setTripData(data.response.list_data)
            setNoDataFound(false);
          }else {
            setNoDataFound(true);
          }  
        } 
        backendMessagesController([data]);
      })
      .catch((errors) => {
        catchErrorsController(
          " Get Vehicle Details Using VIN (permit_order_form.js) --- ",
          errors
        );
      });
  };
  
  const onSuggestionsClearRequested = () => { 
  };
 
  const getSuggestionValue = (suggestion) => { 
    return suggestion.name;
  };

  const renderSuggestion = suggestion => { 
    return <div>
      {suggestion.name}
    </div>
  }; 
   
  const searchKeyupCityHandler = (event, type) => {
    let userInputText = event.value;
     
    setActiveSuggestionTypeName(type) 
    if (userInputText.length >= 3) { 
        Api_AutoSuggestions.getById(userInputText, "cities")
          .then((response) => {
            let data = response.data;  
            if (data.status == 1) {
                const listing_data = data.response.listing_data.map((val)=> { return {_id: val.city, name: val.city}}); 
                setSearchedCityData(listing_data.length > 0 ? listing_data : []);
            }
            backendMessagesController([data]);
          })
        .catch((errors) => {
            catchErrorsController("Association Keyup City Handler ---", errors);
        });
    } else {
        setSearchedCityData([]);
    }
     
  };

  const autoSuggestionCityHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {  
    let type = activeSuggestionTypeName;
    setSearchedCityData([]);  
    setValue(type, suggestion.name);  
  };
   
  const cityElementHandler = (type)=>{ 
     
    return <Autosuggest  
      theme={styles}
      suggestions={searchedCityData}
      onSuggestionsFetchRequested={(event) => searchKeyupCityHandler(event, type)}
      onSuggestionsClearRequested={()=>onSuggestionsClearRequested(type)}
      onSuggestionSelected={autoSuggestionCityHandler}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      highlightFirstSuggestion={true}
      inputProps={{
        placeholder: 'Search by city name',
        value: (type == "start_city") ? cityStartValue : (type == "end_city") ? cityEndValue : '',
        type: "search ",
        className: "form-control",
        disabled: false,
        onChange: function(event, { newValue }) { 
          if(type == "start_city") {
            setCityStartValue(newValue);
          }else if(type == "end_city") {
            setCityEndValue(newValue);
          } 
        },
      }} 
      containerProps={{style: {maxWidth: "unset", width: "50%"}}}  
    />  
  }
 
 
  return (
    <CModal
      visible={isTripDetails}
      onClose={() => tripDetailsCloseHandler(false)} 
      size="xl"
      backdrop="static"
    >
      <CModalHeader>
        <CModalTitle >Recent Trip</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit(getDataHandler)}>
          <CRow>
            <CCol>
              <CFormLabel htmlFor="start_location_zip">Start Trip</CFormLabel>
              <CInputGroup className="mb-3"> 
                {cityElementHandler("start_city")}
                <CFormInput
                  {...register("start_zip")} 
                  id="start_zip"
                  placeholder="Zip"
                  type="number" 
                />
              </CInputGroup>
              {errors.start_city ? (
                <FormFieldError name="start_city" errors={errors} />
              ) : (
                <FormFieldError name="start_zip" errors={errors} />
              )}
            </CCol>
            <CCol>
              <CFormLabel htmlFor="start_location_zip">End Trip</CFormLabel>
              <CInputGroup className="mb-3">
                {/* <CFormInput
                  {...register("end_city")}
                  id="end_city"
                  placeholder="City"
                /> */}
                {cityElementHandler("end_city")}
                <CFormInput
                  {...register("end_zip")}
                  id="end_zip"
                  placeholder="Zip"
                  type="number"
                />
              </CInputGroup>
              {errors.end_city ? (
                <FormFieldError name="end_city" errors={errors} />
              ) : (
                <FormFieldError name="end_zip" errors={errors} />
              )}
            </CCol>
          </CRow>
          <div className="d-flex justify-content-center mt-3">
            <CButton color="primary" size="lg" type="submit">
              Submit
            </CButton>
          </div>
        </CForm>
      </CModalBody>
      {(Array.isArray(tripData) && tripData.length) ?
        <CModalFooter className="p-0">
            <CTable>
            <CTableHead>
                <CTableRow>
                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                <CTableHeaderCell scope="col">Address</CTableHeaderCell>
                <CTableHeaderCell scope="col">City</CTableHeaderCell>
                <CTableHeaderCell scope="col">State</CTableHeaderCell>
                <CTableHeaderCell scope="col">Route State</CTableHeaderCell>
                <CTableHeaderCell scope="col">Route City</CTableHeaderCell>
                <CTableHeaderCell scope="col">Route Freeway</CTableHeaderCell>
                <CTableHeaderCell scope="col">Control</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {tripData.map((data, index)=>{
                return <CTableRow key={data._id}>
                <CTableHeaderCell scope="row">{index+1}</CTableHeaderCell>
                <CTableDataCell>
                    <p className="mb-2">
                        <span className="fs-6 fw-bold text-secondar">Start</span><br />
                        {data.start_address}
                    </p>
                    <p className="">
                        <span className="fs-6 fw-bold text-secondar">End</span><br />
                        {data.end_address}
                    </p>
                </CTableDataCell>
                <CTableDataCell>
                    <p>
                        <span className="fs-6 fw-bold text-secondar">Start</span><br />
                        {data.start_city}
                    </p>
                    <p className="">
                        <span className="fs-6 fw-bold text-secondar">End</span><br />
                        {data.end_city}
                    </p>
                </CTableDataCell>
                <CTableDataCell>
                    <p>
                        <span className="fs-6 fw-bold text-secondar">Start</span><br />
                        {data.start_state}
                    </p>
                    <p className="">
                        <span className="fs-6 fw-bold text-secondary">End</span><br />
                        {data.end_state}
                    </p>
                </CTableDataCell> 
                <CTableDataCell>
                    {data?.route_states?.toString().replaceAll(",", ", ")}
                </CTableDataCell>  
                <CTableDataCell>{data?.route_cities?.toString().replaceAll(",", ", ")}</CTableDataCell>
                <CTableDataCell>{data?.route_freeways?.toString().replaceAll(",", ", ")}</CTableDataCell>
                <CTableDataCell><CButton type="button" color="secondary" onClick={() => {
                    reset()
                    setTripData([])
                    tripDetailsCloseHandler(false)
                    selectTripDetailsHandler(data)
                }}>Select</CButton></CTableDataCell>
                </CTableRow> 
                })}
            </CTableBody>
            </CTable>
        </CModalFooter>
        : ""
      }
      {(noDataFound) ?
        <CModalFooter className="justify-content-start">
          <h5 className="text-danger">No data found</h5>
        </CModalFooter>
        : ""
      }
    </CModal>
  );
});

export default PermitOrderForm;
