import { useEffect, useState } from "react";


const FormFieldError = ({name, errors})=>{  
    return (
        <span className="text-danger"> 
            {errors ? errors[name]?.message : ''}
        </span>
    )
}

export default FormFieldError;